<template>
  <div>
    <v-card v-if="!loading && !loadingOne">
      <v-app-bar dark color="primary" flat dense>
        <v-app-bar-title class="white--text ml-3">{{Object.keys($route.params).length === 0 ? 'Nuevo Comité' : 'Editar Comité'}}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="white" >{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-app-bar>

      <v-form
        ref="form"
        @submit="submit"
      >
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="committee.code"
                label="Código"
                hide-details="auto"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                v-model="committee.description"
                label="Descripción"
                hide-details="auto"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-app-bar
                color="rgba(211, 36, 43, 0.2)"
                flat
                class="mb-3"
              >
                <v-app-bar-title>
                  Lista de Usuarios
                </v-app-bar-title>
                <v-autocomplete
                  v-model="user"
                  class="mx-3"
                  :prepend-icon="icons.mdiMagnify"
                  :items="userList.filter(user => user.roles.some(item => item.role === 'commitee')).filter(user => !committee.users.some(item => item.id === user.id))"
                  label="Añadir Usuario participante"
                  @change="searchUser"
                  solo
                  hide-no-data
                  hide-details
                  flat
                  :menu-props="{contentClass:'list-style'}"
                  item-text="fullname"
                  ref="search_user"
                >
                  <template #item="data">
                    <v-list-item-avatar
                      :color="data.item.avatar ? '' : 'primary'"
                      :class="data.item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    >
                      <v-img
                        v-if="data.item.avatar"
                        :src="require(`@/assets/images/avatars/${data.item.avatar}`)"
                      ></v-img>
                      <span
                        v-else
                        class="font-weight-medium"
                      >{{ avatarText(data.item.fullname) }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title >{{data.item.fullname}}</v-list-item-title>
                      <v-list-item-subtitle><small>{{data.item.email}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-app-bar>
            </v-col>
          </v-row>
          <div v-if="committee.users.length !== 0">
            <v-card-title>
              Participantes
            </v-card-title>
            <v-divider class="mt-2"></v-divider>
            <v-container fluid>
              <v-row no-gutters dense>
                <v-col
                  class="px-1"
                  cols="12"
                  md="4"
                  v-for="(user, index) of committee.users"
                  :key="user.id"
                >
                  <v-card outlined elevation="6" >
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar
                          :color="user.avatar ? '' : 'primary'"
                          :class="user.avatar ? '' : 'v-avatar-light-bg primary--text pointer'"
                        >
                          <img
                            v-if="user.avatar"
                            :src="user.avatar"
                            :alt="user.fullname"
                          >
                          <span
                            v-else
                            class="font-weight-medium"
                          >{{ avatarText(user.fullname) }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ user.fullname }}</v-list-item-title>
                          <v-list-item-subtitle><small>{{ user.email }}</small></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="warning">{{ icons.mdiAccountGroupOutline }}</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-card-actions class="pa-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        small
                        color="primary"
                        class="mr-3"
                        @click="committee.users.splice(index, 1)"
                      >
                        Borrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="pa-5"
          >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loadingSubmit"
              :disabled="committee.users.length === 0"
            >
              Guardar
            </v-btn>
            <v-btn
              @click="reset"
              outlined
              class="mx-2"
            >
              Limpiar
            </v-btn>
            <v-btn
              @click="close"
              color="secondary"
              class="mx-1"
            >
              Retornar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { mdiClose, mdiMagnify, mdiAccountGroupOutline } from '@mdi/js'
import { useRouter } from '@core/utils'
import { validateForm } from '@/@core/utils'
import { isNumber, required } from '@core/utils/validation'
import useCommitteeNew from './useCommitteeNew'
export default {
  setup() {
    const { router } = useRouter()
    const { loading, userList, fetchOne, create, update } = useCommitteeNew()
    const loadingOne = ref(false)
    const loadingSubmit = ref(false)

    const committee = reactive({
      code: '',
      description: '',
      users: [],
    })
    const user = ref('')
    const search_user = ref(null)
    const form = ref(null)

    if (Object.keys(router.currentRoute.params).length !== 0) {
      loadingOne.value = true
      fetchOne(router.currentRoute.params.id)
        .then(res => {
          committee.id = res.id
          committee.code = res.code
          committee.description = res.description
          committee.users = res.users
          loadingOne.value = false
        })
        .catch(error => console.log(error))
    }

    const close = () => {
      router.push({ name: 'committee-list' })
      reset()
    }

    const reset = () => {
      form.value.reset()
      committee.users = []
    }

    const submit = async e => {
      e.preventDefault()
      const isFormValid = validateForm(form)
      if (!isFormValid) return
      loadingSubmit.value = true
      let payload = {
        code: committee.code,
        description: committee.description,
        user_ids: committee.users.map(user => {
          return user.id
        }),
        stated_at: true,
      }
      console.log(payload)

      if (Object.keys(router.currentRoute.params).length === 0) {
        await create(payload)
        loadingSubmit.value = false
        close()
      } else {
        payload.id = committee.id
        await update(payload)
        loadingSubmit.value = false
        close()
      }
    }

    const searchUser = item => {
      committee.users.push(item)
      user.value = ''
      search_user.value.lazyValue = ''
    }

    return {
      avatarText,
      committee,
      close,
      form,
      loading,
      loadingOne,
      loadingSubmit,
      reset,
      searchUser,
      search_user,
      submit,
      userList,
      user,
      icons: {
        mdiAccountGroupOutline,
        mdiClose,
        mdiMagnify,
      },
      validators: {
        isNumber,
        required,
      },
    }
  },
}
</script>

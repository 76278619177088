import store from '@/store'

import { ref, watch } from '@vue/composition-api'

export default function useCommitteeNew() {


  const userList = ref([])
  const loading = ref(false)

  // fetch data
  const fetchUsers = () => {
    loading.value = true
    store
      .dispatch('users/fetchUsers')
      .then(response => {
        const data = ref([])
        if (response) data.value =  response.map(ele => {
          return JSON.parse(ele['listusers']).users
        })

        userList.value = data.value[0].map(user => {
          return {
            ...user,
            value: user,
          }
        })

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('committees/fetchOne', id)
      const data = ref([])
      data.value = res.map(ele => {
        return JSON.parse(ele['listcommittees']).committees
      })
        return data.value[0][0]
    } catch (error) {
    }
  }

  const create = async payload => {
    try {
      const res = await store.dispatch('committees/create', payload)
      return res
    } catch (error) {

    }

  }

  const update = async payload => {
    try {
      const response = await store.dispatch('committees/update', payload)
      return response
    } catch (error) {

    }
  }

  fetchUsers()


  watch([], () => {
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*



  return {
    create,
    fetchOne,
    loading,
    update,
    userList
  }
}
